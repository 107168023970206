.container {
  height: 100vw;
  padding: 20px;

  background: #72510b; /* fallback for old browsers */
  background: linear-gradient(to left, #bf7301, #6f4212);

  color: #171212;
}

.headlinePrimary {
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 2px;
  color: #ffffff
}

.item {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.item > span {
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item > span > a {
  color: inherit;
}

.button {
  background: transparent;
  border: 1px solid #ffffff;
  padding: 5px;
  cursor: pointer;
  color:#ffffff;


  transition: all 0.1s ease-in;
}

.button:hover {
  background: #cc710f;
  color: #ffffff;
}

.buttonSmall {
  padding: 5px;
}

.buttonLarge {
  padding: 10px;
}

.searchForm {
  padding: 10px 0 20px 0;
  display: flex;
  align-items: baseline;
}

.label {
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;;
  padding-left: 5px;
  font-size: 24px;
}

.input {
  border: none;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  background-color: transparent;

  font-size: 24px;
}

.activeSort {
  color: #ffffff;
  font-weight: bold;
  background-color: #cc710f;
}